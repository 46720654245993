<template>
  <div id="CategoryCollection">
    <!-- CATEGORY SECTION -->
    <section v-if="categories" class="section">
      <div class="container">
        <h1 class="title">{{ $t("category.title") }}</h1>
        <section class="section">
          <div class="columns is-multiline">
            <div
              v-for="(category, index) in categories"
              :key="index"
              class="column is-4"
            >
              <category-card :categoryItem="category" />
            </div>
          </div>
        </section>
      </div>
    </section>
    <section v-else class="hero is-large">
      <div class="hero-body">
        <div class="container">
          <h2 class="title">
            {{ $t("label.loading") }}
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import ProductApiManager from "@/api/ProductApiManager";
import CategoryCard from "@/components/cards/CategoryCard.vue";

export default {
  name: "CategoryCollection",
  components: {
    CategoryCard
  },
  watch: {
    current_lang: function(val) {
      if (val) {
        this.categories = null;
        this.getCategoryList();
      }
    }
  },
  computed: mapState(["current_lang"]),
  data() {
    return {
      categories: null
    };
  },
  methods: {
    getCategoryList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApiManager.requestCategories();
      switch (response.code) {
        case "0":
          this.categories = response.data;
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    navigateToCategoryDetail: function(category) {
      this.$router.push({
        name: "category-detail",
        params: { category }
      });
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getCategoryList();
  }
};
</script>
