<template>
  <div
    v-if="categoryInfo"
    class="card hoverable"
    @click="navigateToCategoryDetail(categoryInfo)"
  >
    <div class="card-image">
      <figure class="image is-3by2">
        <img
          class="card-img"
          :src="categoryInfo.cover_image"
          alt="Placeholder image"
        />
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content hide_scroll">
          <p class="title is-4">{{ categoryInfo.title }}</p>
        </div>
      </div>
      <v-clamp class="has-text-left" autoresize :max-lines="4">
        {{ categoryInfo.description }}
        <template v-if="clamped" slot="after" slot-scope="{ clamped }">
          <!-- The code below goes into the header slot -->
          <a @click="navigateToCategoryDetail(categoryInfo)">
            {{ $t("btn.read_more") }}
          </a>
        </template>
      </v-clamp>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  components: {
    VClamp
  },
  name: "CategoryCard",
  props: ["categoryItem"],
  data() {
    return {
      categoryInfo: this.categoryItem
    };
  },
  methods: {
    navigateToCategoryDetail: function(category) {
      const category_id = category._id;
      this.$router.push({
        name: "category-detail",
        params: { category: category_id }
      });
    }
  }
};
</script>

<style>
.card {
  height: 100%;
  border-radius: 10px;
}
.card-img {
  object-position: center;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
