import MainConnection from "./MainConnection";

export default {
  requestCategories: async function() {
    try {
      const requestURL = "/categories";
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestCategoryDetail: async function(category_id) {
    try {
      const requestURL = "/categories/" + category_id;
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestCategoryProducts: async function(category_id) {
    try {
      const requestURL = "/products?cat_id=" + category_id;
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestCategoryProductDetail: async function(product_id) {
    try {
      const requestURL = "/products/" + product_id;
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestNewestProductList: async function(limit) {
    try {
      const requestURL = "/products?limit=" + limit;
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
